export const formatDate = (value: string = '', timeDir: boolean = false) => {
    let date;
    if (value === '') {
        date = new Date();
    } else {
        date = new Date(value);
    }
    if (timeDir) {
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    } else {
        return date.toLocaleDateString();
    }
};

export const queryData = (value: string) => {
    return new Date(value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
};

export const formatDateDB = (value: Date) => {
    return value.toLocaleDateString().replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1');
};

export const formatDateGET = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

export const getTimeHM = (timestamp: number) => {
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - hours * 60;
    return hours === 0 ? minutes + ' мин' : hours + ' ч. ' + minutes + ' мин';
};

export type WeekendCalendarDay = {
    d: string; // Дата в формате YYYY-MM-DD
    t: string; // Тип (1 = нерабочий день, 2 = сокращенный рабочий день, 3 = рабочий день)
    h?: string; // Дополнительная информация
    f?: string; // Привязка к дате переноса
};

export const countWorkdays = (
    startDate: Date,
    endDate: Date,
    weekendCalendar: WeekendCalendarDay[],
): number => {
    const specialDayMap = new Map<string, number>();

    // Создаём мапу с указанием типа дня
    weekendCalendar.forEach((day) => {
        specialDayMap.set(day.d, parseInt(day.t, 10));
    });

    let workdaysCount = 0;
    const currentDate = new Date(startDate);

    // Итерация по дням в диапазоне
    while (currentDate <= endDate) {
        const isoDate = currentDate.toISOString().split('T')[0]; // Преобразуем в формат YYYY-MM-DD
        const dayOfWeek = currentDate.getDay(); // День недели: 0 = воскресенье, 6 = суббота

        const specialType = specialDayMap.get(isoDate);

        if (specialType === 1) {
            // Нерабочий день, пропускаем
        } else if (specialType === 2) {
            // Сокращенный рабочий день
            workdaysCount++;
            //console.log(`Рабочий день (сокращенный): ${isoDate} (t=2)`);
        } else if (specialType === 3) {
            // Выходной стал рабочим
            workdaysCount++;
            //console.log(`Рабочий день: ${isoDate} (t=3)`);
        } else if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            // Стандартный рабочий день (не суббота, воскресенье)
            workdaysCount++;
            //console.log(`Рабочий день: ${isoDate} (по умолчанию)`);
        }

        // Переходим к следующему дню
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return workdaysCount;
};
