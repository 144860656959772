import axios from 'axios';
import { api } from '../../../reusableComponents';
import getQueryString from '../helper';
import { SalaryPeriodQuery } from '../../types/salary';

export const salaryWeekendApi = (year: Number) => {
    return axios.get(api.url_module + `salary/api/weekend-v2/${year}`, api.config);
};

export const salaryPeriodApi = (params: SalaryPeriodQuery) => {
    return axios.get(
        api.url_module + `salary/api/period/${params.employee_id}?${getQueryString(params)}`,
        api.config,
    );
};
