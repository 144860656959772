import {
    SALARY_KICKIDLER_REPORT,
    SALARY_TASK_ELAPSED,
    SALARY_WORKTIME_REPORT,
    SALARY_MARK_REPORT,
    SALARY_WEEKEND,
    SALARY_PERIOD,
    SALARY_USER,
    SALARY_DATE_RANGE,
    SALARY_IS_LOAD,
    SALARY_REAL_TIME,
} from './salaryDetailNew.types';

export const salarySetLoadAction = (payload: any) => ({
    type: SALARY_IS_LOAD,
    payload,
});

export const salaryUserAction = (id: number) => ({
    type: SALARY_USER,
    payload: id,
});

export const salaryDateRangeAction = (payload: any) => ({
    type: SALARY_DATE_RANGE,
    payload,
});

export const salaryRealTimeAction = (payload: any) => ({
    type: SALARY_REAL_TIME,
    payload,
});

//OLD

export const salaryKickidlerReportAction = (payload: any) => ({
    type: SALARY_KICKIDLER_REPORT,
    payload,
});

export const salaryWorktimeReportAction = (payload: any) => ({
    type: SALARY_WORKTIME_REPORT,
    payload,
});

export const salaryTaskElapsedAction = (payload: any) => ({
    type: SALARY_TASK_ELAPSED,
    payload,
});

export const salaryMarkReportAction = (payload: any) => ({
    type: SALARY_MARK_REPORT,
    payload,
});

export const salaryWeekendAction = (payload: any) => ({
    type: SALARY_WEEKEND,
    payload,
});

export const salaryPeriodAction = (payload: any) => ({
    type: SALARY_PERIOD,
    payload,
});
