import { SalaryDetailFilterProps } from '../../../pageComponents/SalaryDetail/SalaryDetail.props';
import moment from 'moment';

import {
    SALARY_KICKIDLER_REPORT,
    SALARY_MARK_REPORT,
    SALARY_PERIOD,
    SALARY_TASK_ELAPSED,
    SALARY_WEEKEND,
    SALARY_WORKTIME_REPORT,
    SALARY_USER,
    SALARY_DATE_RANGE,
    SALARY_IS_LOAD,
    SALARY_REAL_TIME,
} from '../../actions/salaryDetailNew/salaryDetailNew.types';

import {
    salaryCalculationApi,
    activityListApi,
    eventListApi,
} from '../../servise/salaryDetail/salaryDetail';

import {
    salaryCalculationAction,
    activityListAction,
    eventListAction,
} from '../../actions/salaryDetail/salaryDetail';

import {
    salaryDateRangeAction,
    salaryKickidlerReportAction,
    salaryMarkReportAction,
    salaryPeriodAction,
    salaryRealTimeAction,
    salarySetLoadAction,
    salaryTaskElapsedAction,
    salaryUserAction,
    salaryWeekendAction,
    salaryWorktimeReportAction,
} from '../../actions/salaryDetailNew/salaryDetailNew';
import { kickidlerReportApi } from '../../servise/kickidler/kickidler';
import { ReportItem } from '../../types/kickidler';
import { worktimeGroupApi } from '../../servise/worktime/worktime';
import { WorktimeGroupItem, WorktimeGroupQuery } from '../../types/worktime';
import { taskElapsedGroupApi } from '../../servise/taskElapsed/taskElapsed';
import { fetchApiTaskList } from '../../servise/task/task';
import { salaryPeriodApi, salaryWeekendApi } from '../../servise/salary/salary';
import { GetUserProfileApi } from '../../servise/user';
import { formatDateGET } from '../../../helpers/formatDate';

const initialState = {
    isLoad: true,
    employe: null,
    range: [null, null],
    kickidlerReport: [],
    worktimeReport: [],
    realtimeReport: [],
    taskElapsed: [],
    markReport: [],
    weekend: [],
    period: [],
};

export const salaryDetailNewReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SALARY_IS_LOAD:
            return {
                ...state,
                isLoad: action.payload,
            };
        case SALARY_USER:
            return {
                ...state,
                employe: action.payload,
            };
        case SALARY_DATE_RANGE:
            return {
                ...state,
                range: [
                    new Date(action.payload.from.split('.').reverse().join('-')),
                    new Date(action.payload.to.split('.').reverse().join('-')),
                ],
            };
        case SALARY_REAL_TIME:
            return {
                ...state,
                realtimeReport: action.payload,
            };
        case SALARY_KICKIDLER_REPORT:
            return {
                ...state,
                kickidlerReport: action.payload,
            };
        case SALARY_WORKTIME_REPORT:
            return {
                ...state,
                worktimeReport: action.payload,
            };
        case SALARY_TASK_ELAPSED:
            return {
                ...state,
                taskElapsed: action.payload,
            };
        case SALARY_MARK_REPORT:
            return {
                ...state,
                markReport: action.payload,
            };
        case SALARY_WEEKEND:
            return {
                ...state,
                weekend: action.payload,
            };
        case SALARY_PERIOD:
            return {
                ...state,
                period: action.payload,
            };
        default:
            return state;
    }
};
export default salaryDetailNewReducer;

export const setIsLoad = (value: boolean) => {
    return (dispatch: any) => {
        dispatch(salarySetLoadAction(value));
    };
};

export const setEmployee = (id: number) => {
    return async (dispatch: any) => {
        await GetUserProfileApi(id).then((r) => {
            dispatch(salaryUserAction(r.data));
        });
    };
};

export const setDateRange = (from: string, to: string) => {
    return async (dispatch: any) => {
        await dispatch(salaryDateRangeAction({ from, to }));
    };
};

export const agregateData = (id: number, range: Array<Date>) => {
    let CWorkTime: WorktimeGroupItem[];
    let CKickidlerTime: ReportItem[];
    return async (dispatch: any) => {
        try {
            // Получаем выходные дни
            const weekendData = await salaryWeekendApi(range[0].getFullYear());
            dispatch(salaryWeekendAction(weekendData.data));

            // Получаем установленную зарплату
            const periodData = await salaryPeriodApi({
                employee_id: id,
                date_from: formatDateGET(range[0]),
                date_to: formatDateGET(range[1]),
            });
            dispatch(salaryPeriodAction(periodData.data));

            // Получаем рабочее время по Кикидлеру
            const kickidlerData = await kickidlerReportApi({
                id: id,
                dateFrom: formatDateGET(range[0]),
                dateTo: formatDateGET(range[1]),
            });
            CKickidlerTime = kickidlerData.data;
            if (kickidlerData.data.code && kickidlerData.data.code == 400) {
                dispatch(salaryKickidlerReportAction([]));
            } else {
                dispatch(salaryKickidlerReportAction(kickidlerData.data));
            }

            // Получаем рабочее время по Битриксу
            const worktimeData = await worktimeGroupApi({
                select: 'duration',
                responsible_id: id,
                date_from: formatDateGET(range[0]),
                date_to: formatDateGET(range[1]),
                group_by: 'date',
            });
            CWorkTime = worktimeData.data.result;
            dispatch(salaryWorktimeReportAction(worktimeData.data.result));

            // Получаем эффективное время по задачам
            const taskElapsedData = await taskElapsedGroupApi({
                responsible_id: id,
                finish_date_from: formatDateGET(range[0]),
                finish_date_to: formatDateGET(range[1]),
                group_by: ['task', 'date'],
            });
            dispatch(salaryTaskElapsedAction(taskElapsedData.data.result));
            // Получаем оценку по задачам
            const taskMarkData = await fetchApiTaskList({
                responsible_id: id,
                mark: [1, 2, 3, 4, 5],
                finish_date_from: formatDateGET(range[0]),
                finish_date_to: formatDateGET(range[1]),
            });

            dispatch(salaryMarkReportAction(taskMarkData.result));
        } catch (err) {
            console.error('Ошибка в agregateData:', err);
        } finally {
            const realtimeData = synchronTimeData(range, CWorkTime, CKickidlerTime);
            dispatch(salaryRealTimeAction(realtimeData));
            dispatch(salarySetLoadAction(false));
        }
    };
};

const synchronTimeData = (
    range: Date[],
    workTime: WorktimeGroupItem[],
    kickidlerTime: ReportItem[],
) => {
    const result: { [date: string]: number } = {}; // Объект для хранения результата
    // Преобразуем даты в строковый формат (Y-m-d) для удобства сравнения
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);

    // Пройдем по диапазону дат
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split('T')[0]; // Преобразуем дату в строку формата Y-m-d

        const workTimeItem = workTime.find((item) => item.date === dateString);
        const kickidlerTimeItem = kickidlerTime.find((item) => item.date === dateString);

        // Если оба значения существуют, берем минимальное
        if (workTimeItem && kickidlerTimeItem) {
            result[dateString] = Math.min(
                workTimeItem.work_time,
                kickidlerTimeItem.value.total_time,
            );
        } else {
            // Если хотя бы одно значение отсутствует, итоговое значение 0
            result[dateString] = 0;
        }
    }

    // Возвращаем результат в виде массива {день: значение}
    return Object.entries(result).map(([date, value]) => ({ date, value }));
};

/**
 * Получаем данные [Эффективное время]
 * @param filter
 * @returns
 */
export const fetchEffectiveTime = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await salaryCalculationApi(filter).then((r) => {
            dispatch(salaryCalculationAction(r.data[0]));
        });
    };
};

/**
 * Получаем список дел [Эффективное время]
 * @param {*} userId
 * @param {*} date
 * @returns
 */
export const fetchActivityList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await activityListApi(filter).then((r: any) => {
            const deals = r.data.result.map((el: any) => {
                const startTime = moment(el.start_time, 'YYYY-MM-DD HH:mm:ss');
                const endTime = moment(el.end_time, 'YYYY-MM-DD HH:mm:ss');
                el.time = endTime.diff(startTime, 'second');
                return el;
            });

            dispatch(activityListAction(r.data));
        });
    };
};

/**
 * Получаем список событий календаря [Эффективное время]
 * @param {*} userId
 * @param {*} date
 * @returns
 */
export const fetchEventList = (filter: SalaryDetailFilterProps) => {
    return async (dispatch: any) => {
        await eventListApi(filter).then((r: any) => {
            dispatch(eventListAction(r.data));
        });
    };
};
