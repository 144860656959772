export const SALARY_KICKIDLER_REPORT = 'SALARY_KICKIDLER_REPORT';
export const SALARY_WORKTIME_REPORT = 'SALARY_WORKTIME_REPORT';
export const SALARY_TASK_ELAPSED = 'SALARY_TASK_ELAPSED';
export const SALARY_MARK_REPORT = 'SALARY_MARK_REPORT';
export const SALARY_WEEKEND = 'SALARY_WEEKEND';
export const SALARY_PERIOD = 'SALARY_PERIOD';
export const SALARY_USER = 'SALARY_USER';
export const SALARY_DATE_RANGE = 'SALARY_DATE_RANGE';
export const SALARY_IS_LOAD = 'SALARY_IS_LOAD';
export const SALARY_REAL_TIME = 'SALARY_REAL_TIME';
